


































import { Component, Vue } from 'vue-property-decorator';

import './scss/PopupAddProjectMember.scss';
import { namespace } from 'vuex-class';

const NSProject = namespace('storeProject');

@Component({
    name: 'PopupAddProjectMember',
    components: {
        BasePopup: () => import('@components/popups/BasePopup.vue'),
        BaseViewToggler: () => import('@components/BaseViewToggler'),
        BaseInput: () => import('@components/BaseInputs/BaseInput.vue'),
        BaseSelect: () => import('@components/BaseSelect/BaseSelect.vue'),
        BaseFileInput: () => import('@components/BaseFileInput/BaseFileInput.vue'),
        AddMemberForm: () => import('../../pages/about-project/components/AddMemberForm.vue'),
        AddMemberJuristicForm: () => import('../../pages/about-project/components/AddMemberJuristicForm.vue'),
    }
})

export default class PopupAddProjectMember extends Vue {
    $refs!: {
        basePopup: HTMLFormElement,
        addMemberForm: HTMLFormElement,
        addMemberJuristicForm: HTMLFormElement,
    }

    viewMode: string = 'privatePerson';
    viewModes: any[] = [
        {
            label: 'Физическое лицо',
            value: 'privatePerson',
        }, {
            label: 'Организация',
            value: 'company',
        },
    ];
    btnDisabled = false;
    loading: boolean = false;

    openPopup() {
        if (this.$refs.basePopup) {
            this.$refs.basePopup.openPopup();
        }
    }

    closePopup() {
        this.loading = false;
        if (this.$refs.basePopup) {
            this.$refs.basePopup.closePopup();
        }
    }

    changeViewMode(e) {
        this.viewMode = e.value;
    }

    onSuccess() {
        if (this.viewMode === 'privatePerson') {
            this.$refs.addMemberForm.checkSend();
        } else {
            this.$refs.addMemberJuristicForm.checkSend();
        }
    }

    onClosePopup() {
        if (this.viewMode === 'privatePerson') {
            this.$refs.addMemberForm.resetData();
        } else {
            this.$refs.addMemberJuristicForm.resetData();
        }
    }

    changeBtnDisabled(val) {
        this.btnDisabled = val;
    }

    emitConfirmAddMember(type: string) {
        this.loading = true;
        this.$emit('confirm-add-member', type);
    }
}
